import React from "react"
import { cleanUpPathname } from "./utils/index"
import Analytics from "./utils/analytics"
import ReactModal from 'react-modal';

export default class OptInForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      form_interest: props.opt_in_form.form_interest,
      mailchimp_group_id: props.opt_in_form.mailchimp_group_id,
      mailchimp_group_list_id: props.opt_in_form.mailchimp_group_list_id,
      mailchimp_tag_id: props.opt_in_form.mailchimp_tag_id,
      submitButtonDisabled: false,
      asyncRequests: [],
      form: "",
      sidebar: this.props.sidebar,
      code: this.props.code,
      isSubmittedModalOpen: false,
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    let {
      email,
      form_interest,
      mailchimp_group_id,
      mailchimp_group_list_id,
      mailchimp_tag_id,
      sidebar,
      code,
    } = this.state
    this.setState({ form: e.target })

    this.setState({ submitButtonDisabled: true })
    Analytics.identifyUser(
      email,
      {
        id: email,
        email: email,
        interestedProduct: form_interest,
      },
      () => {
        console.log(`opt in: ${this.state.asyncRequests}`)
        this._checkAllAsyncRequestsCompleted("identifyUser")
      }
    )

    window.analytics.track(
      "Opted-in",
      {
        form_name: cleanUpPathname(),
        opt_in_page: cleanUpPathname(),
      },
      () => {
        console.log(`opt in: ${this.state.asyncRequests}`)
        this._checkAllAsyncRequestsCompleted("trackOptedIn")
      }
    )

    if (sidebar) {
      Analytics.sendCodeToSubscriber(
        email,
        mailchimp_group_id,
        mailchimp_group_list_id,
        mailchimp_tag_id,
        this.props.code
      ).then(() => {
        debugger
        this._checkAllAsyncRequestsCompleted("mailchimpSubmit")
      })
    } else {
      Analytics.submitToMailchimp(
        email,
        mailchimp_group_id,
        mailchimp_group_list_id,
        mailchimp_tag_id
      ).then(() => {
        debugger
        this._checkAllAsyncRequestsCompleted("mailchimpSubmit")
      })
    }
  }

  _checkAllAsyncRequestsCompleted(name) {
    let { form, email, asyncRequests } = this.state
    asyncRequests.push(name)
    let numAsyncReq
    if (process.env.NODE_ENV === "production") {
      numAsyncReq = 3
    } else {
      numAsyncReq = 2
    }
    if (asyncRequests.length === numAsyncReq) {
      asyncRequests = []
      this.setState({isSubmittedModalOpen: true})
      //window.location.href = form.action + "?email=" + email
    }
  }

  _cleanUpPathname() {
    var path = window.location.pathname.replace(/\.html/, "").replace(/\//, "")
    if (path === "") {
      return "home"
    } else {
      return path
    }
  }

  handleModalClose = event => {
    this.setState({ isSubmittedModalOpen: false })
  }

  render() {
    const { opt_in_form, background_color, sidebar, btn_color } = this.props

    return (
      <>
        {!sidebar ? (
          <section
            id="cta"
            className={background_color ? "cta" : "cta bg-gradient-blue-green"}
            style={
              background_color ? { backgroundColor: background_color } : {}
            }
          >
            <div className="container text-center">
              <div className="row mt-0">
                <div className="col-md-8 offset-md-2">
                  <div className="form-holder">
                    <h2
                      dangerouslySetInnerHTML={{ __html: opt_in_form.header }}
                    ></h2>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: opt_in_form.description,
                      }}
                    ></p>
                    <form
                      id="lead-form"
                      name={opt_in_form.form_name}
                      action={opt_in_form.form_action}
                      onSubmit={this.handleSubmit}
                    >
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Enter Your Email Address"
                          value={this.state.email}
                          onChange={this.handleChange}
                          required
                        />
                        <input
                          type="hidden"
                          name="interest"
                          value={opt_in_form.form_interest}
                        />
                        <button
                          type="submit"
                          className={btn_color ? "btn submit one-liner" : "btn btn-primary btn-gradient submit one-liner"}
                          disabled={this.state.submitButtonDisabled}
                          style={
                            btn_color ? { backgroundColor: btn_color, color: '#fff' } : {}
                          }
                        >
                          {opt_in_form.form_submit}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <div className="row p-2">
            <div className="col-md-12">
              <form
                id="lead-form"
                name={opt_in_form.form_name}
                action={opt_in_form.form_action}
                onSubmit={this.handleSubmit}
              >
                <div className="form-group">
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter Your Email Address"
                    value={this.state.email}
                    onChange={this.handleChange}
                    required
                  />
                  <input
                    type="hidden"
                    name="interest"
                    value={opt_in_form.form_interest}
                  />
                  <div className="mt-1">
                    <button
                      type="submit"
                      className="btn btn-primary btn-gradient submit btn-block"
                      disabled={this.state.submitButtonDisabled}
                      style={{ borderRadius: "0" }}
                    >
                      {opt_in_form.form_submit}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
        <ReactModal
          isOpen={this.state.isSubmittedModalOpen}
          onRequestClose={this.handleModalClose}
          contentLabel="Submitted"
          className="Modal card"
          //style={customStyles}
        >
          <div className="text-center m-3" style={{background: "white", maxHeight: "90vh", maxWidth: "90vw"}}>
            <div>
              <p className="m-3 text-center text-dark"><span role="img" aria-labelledby="yay">🎉</span> Awesome! You will receive an email soon.</p>
            </div>
            <div>
              <button onClick={this.handleModalClose} className="btn btn-lg btn-primary">Close</button>
            </div>
          </div>
        </ReactModal>
      </>
    )
  }
}
