function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class {
  static identifyUser(email, options, callback) {
    // window.fcWidget.user.setEmail(email);
    window.analytics.identify(email, options, callback)
  }

  static trackEvent(eventName, properties) {
    window.analytics.track(eventName, properties)
  }

  static submitToMailerlite(email, funnel_id, interest) {
    // Zapier
    return fetch("https://hooks.zapier.com/hooks/catch/3349809/a8ql3a/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ email: email, interest: interest, funnel_id: funnel_id }),
    })

    // Automate.io company account
    // return fetch(`https://api.automate.io/webhook/5b2859cf85bdfd23ba1a1130?email=${email}&interest=${interest}&funnel_id=${funnel_id}`)

    // Automate.io personal account
    // return fetch(`https://api.automate.io/webhook/5b39dd4894374e6590d532ba?email=${email}&interest=${interest}&funnel_id=${funnel_id}`)
  }
  static submitToMailchimp(
    email,
    mailchimp_group_id,
    mailchimp_group_list_id,
    mailchimp_tag_id
  ) {
    // Automate.io pynkvalerian@gmail.com account
    // return fetch("https://wh.automate.io/webhook/5d299d457fdc9f102eef96ed", {

    // Automate.io ashleytanmp@gmail.com account
    // return fetch("https://wh.automate.io/webhook/5d312ee3ded92b7f6e0b4865", {

    // Automate.io company account
    return fetch("https://wh.automate.io/webhook/5d146f17b4502e66046abcc8", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        email: email,
        group_id: mailchimp_group_id,
        group_list_id: mailchimp_group_list_id,
        tag_id: mailchimp_tag_id,
      }),
    })
    return
  }

  static sendCodeToSubscriber(
    email,
    mailchimp_group_id,
    mailchimp_group_list_id,
    mailchimp_tag_id,
    discount_code
  ) {
    // Automate.io pynkvalerian@gmail.com account
    // return fetch("https://wh.automate.io/webhook/5d299d457fdc9f102eef96ed", {

    // Automate.io ashleytanmp@gmail.com account
    // return fetch("https://wh.automate.io/webhook/5d312ee3ded92b7f6e0b4865", {

    // Automate.io company account
    return fetch("https://wh.automate.io/webhook/5f19355d025ad70289652f0c", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        email: email,
        group_id: mailchimp_group_id,
        group_list_id: mailchimp_group_list_id,
        tag_id: mailchimp_tag_id,
        discount_code: discount_code,
      }),
    }).then(function(resp) {
      alert("Thank you ! We'll send discount code to your email shortly.")
    })
    return
  }

  static submitToSlack(details) {
    let text = `*New Partner Lead* ${details.first_name} ${details.last_name} (${details.phone} ${details.email}) from ${details.company} has left a message ${details.message}`

    return fetch(
      "https://hooks.slack.com/services/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",
      {
        method: "POST",
        headers: { Accept: "application/json" },
        body: JSON.stringify({
          channel: "#bizdev",
          username: "PartnerLeads",
          text: text,
        }),
      }
    )
  }
}
