import React from 'react'
import ReactCardFlip from 'react-card-flip';

export default class FlippingCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFlipped: false
    };
  }

  handleMouseEnter = () => {
    this.setState({ isFlipped: true });
  }

  handleMouseLeave = () => {
    this.setState({ isFlipped: false });
  }

  render() {
    const {title, description, img} = this.props.item
    return (
      <ReactCardFlip isFlipped={this.state.isFlipped}>
        <div className="card" key="front" onMouseEnter={this.handleMouseEnter} onClick={this.handleMouseEnter} style={{background: `url(${img}) no-repeat center`}}>
          <div className="card-body d-flex justify-content-center align-items-center">
          </div>
        </div>

        <div className="card" key="back" onMouseLeave={this.handleMouseLeave} onClick={this.handleMouseLeave}>
          <div className="card-header">
            {title}
          </div>
          <div className="card-body d-flex justfy-content-center align-items-center">
            <p className="" dangerouslySetInnerHTML={{__html: description}}></p>
          </div>
        </div>
      </ReactCardFlip>
    )
  }
}
