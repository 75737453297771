import React from 'react'
import {Link, withPrefix, graphql} from 'gatsby'
import Layout from '../components/layouts/layout'
import HeadMeta from '../components/layouts/headmeta'
import OptInForm from '../components/optinform'
import ProgressBar from '../components/layouts/progressbar'
import FlippingCard from '../components/flippingcard'
import { changeProgressBarOnScroll } from '../components/utils/index'

export default class DiscoverGeneticsPage extends React.Component {
  componentDidMount() {
    window.addEventListener('scroll', function() {
      changeProgressBarOnScroll()
    });
    window.analytics.page('Static', 'Discover Genetics')

    // load brandquiz script
    const script = document.createElement("script");
    script.src = "https://app.brandquiz.io/embed";
    script.async = true;
    document.body.appendChild(script);
  }

  render() {

    const { data } = this.props
    const { frontmatter } = data.markdownRemark
    const { metadata, title, description, button_text, did_you_know, opt_in_form } = frontmatter

    return (
      <Layout>
        <div id="discover-genetics">
          <HeadMeta metadata={ metadata } />
          <ProgressBar />
          <section className="hero hero-home hero-dna-day bg-gray" style={{backgroundImage: `url(/img/dna-day/hero3.png)`}}>
            <div className="container">
              <div className="text-center mt-md-3">
                <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
                <p className="lead" dangerouslySetInnerHTML={{ __html: description }}></p>
                <div className="CTA">
                  <Link to="#explainer" className="btn btn-primary btn-shadow btn-gradient link-scroll">{button_text}</Link>
                </div>
              </div>
            </div>
          </section>
          <section id="explainer" className="container mb-0 pb-3 pt-5">
            <div className="row d-flex align-items-center justify-content-center">
              <div className="col-md-6" id="about-image">
                <iframe title="explainer" id="explainer-video" width="500px" height="300px" src="https://player.vimeo.com/video/60747882?color=ef3239&title=0&byline=0&portrait=0" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                <small><a href="https://vimeo.com/60747882">BBC Knowledge Explainer</a> from Territory.</small>
              </div>
              <div className="col-md-6">
                <p><small>Discover&nbsp;&nbsp;|&nbsp;&nbsp;#WhatIsDNA</small></p>
                <h2>The <em>Blueprint</em> of Life.</h2>
                <p className="lead"><strong>DNA</strong>. The blueprint that makes you, <em>you</em>.<br/>What exactly is DNA and how does it make you unique?</p>
                <p className="lead">Watch this video to learn more.</p>
              </div>
            </div>
          </section>
          <section className="container-fluid testimonials pb-5" id="discover">
            <header className="text-center no-margin-bottom">
              <h2>The #DiscoverGenetics Series</h2>
              <p className="lead">Learn more about genetics through our #DiscoverGenetics series.</p>
            </header>
            <div className="row">
              <div className="item-holder col-sm-4">
                <a href="https://blog.advanxhealth.com/2018-04-24-understanding-genetics-what-is-dna/" className="blogpost-card-link">
                  <div className="card">
                    <img className="card-img-top" src={withPrefix('/img/dna-day/understanding-genetics.png')} alt="understanding genetics" />
                    <div className="card-body text-center">
                      <small className="blogpost-date">24th April 2018</small>
                      <h5 className="card-title">Understanding Genetics</h5>
                      <p className="card-text">There are less than 1% genetic variations between humans, and yet that is enough to make us completely different from each other.</p>
                      <a href="https://blog.advanxhealth.com/2018-04-24-understanding-genetics-what-is-dna/">Read more ></a>
                    </div>
                  </div>
                </a>
              </div>
              <div className="item-holder col-sm-4">
                <a href="https://blog.advanxhealth.com/2018-04-26-common-dna-myth-misconceptions/" className="blogpost-card-link">
                  <div className="card" >
                    <img className="card-img-top" src={withPrefix('/img/dna-day/common-dna-myths.jpg')} alt="common myths" />
                    <div className="card-body text-center">
                      <small className="blogpost-date">26th April 2018</small>
                      <h5 className="card-title">Common DNA Myths</h5>
                      <p className="card-text">What are the common misconceptions about genetics? Find out as we dispel these common myths.</p>
                      <a href="https://blog.advanxhealth.com/2018-04-26-common-dna-myth-misconceptions/">Read more ></a>
                    </div>
                  </div>
                </a>
              </div>
              <div className="item-holder col-sm-4">
                <a href="https://blog.advanxhealth.com/2018-04-26-diy-dna-extraction-science-project-at-home/" className="blogpost-card-link">
                  <div className="card" >
                    <img className="card-img-top" src={withPrefix('/img/dna-day/diy-dna.jpg')} alt="DIY DNA extraction" />
                    <div className="card-body text-center">
                      <small className="blogpost-date">26th April 2018</small>
                      <h5 className="card-title">DIY DNA Extraction</h5>
                      <p className="card-text">A fun and interactive way to test extract DNA from a strawberry with household items. Try it out yourself!</p>
                      <a href="https://blog.advanxhealth.com/2018-04-26-diy-dna-extraction-science-project-at-home/">Read more ></a>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </section>
          <section className="container section-narrow" id="cofounder-thoughts">
            <div className="row d-flex">
              <a href="https://blog.advanxhealth.com/2018-04-25-dna-day-2018-celebrating-genomics-through-awareness/" className="blogpost-card-link">
                <div className="col-md-7 card mt-xs-0 my-5 order-2" style={{zIndex: '100'}}>
                  <div className="card-body">
                    <small>25th April 2018</small>
                    <h3>Our co-founder shares his thoughts on genomics and personalised healthcare.</h3>
                    <a href="https://blog.advanxhealth.com/2018-04-25-dna-day-2018-celebrating-genomics-through-awareness/">Read more ></a>
                  </div>
                </div>
              </a>
            </div>
            <img className="banner-img" src={withPrefix('/img/dna-day/banner.png')} alt="DNA Day"/>
          </section>
          <section className="container text-center" id="do-you-know">
            <h2>Do You Know?</h2>
            <p className="lead">Many parts of our daily lives are influenced by genetic information, hover/click over each card to find out how it affects your daily lives!</p>
            <div className="row">
              {
                did_you_know.map((item, index) => (
                  <div className="col-md-4 flippingcard-wrapper">
                    <FlippingCard item={item} key={index}/>
                  </div>
                ))
              }
            </div>
          </section>
          <section className="section container text-center pt-0">
            <h2 className="mb-5"><em>Psst.</em> Are you up for a challenge?</h2>
            <p>Test your understanding with this quick quiz.</p>
            <div className="brandquiz_embed" data-embed="advanxhealth/dna-day-quiz" style={{maxWidth: '100%'}}>
              <iframe title="quiz" frameborder="0" style={{width: '100%', overflow: 'hidden', height: '500px'}} src="https://app.brandquiz.io/advanxhealth/dna-day-quiz?embed=1&amp;src=https%3A%2F%2Fadvanxhealth.com%2Fdna-day-2018"></iframe>
            </div>
          </section>
          <OptInForm opt_in_form={opt_in_form} />
        </div>
      </Layout>
    )
  }
}


export const DiscoverGeneticsPageQuery = graphql`
  query DiscoverGeneticsPage($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        metadata {
          title
          description
          image
          url
        }
        title
        description
        button_text
        did_you_know {
          title
          description
          img
        }
        opt_in_form {
          header
          description
          form_name
          form_action
          form_interest
          form_submit
          form_funnel_id
          mailchimp_group_id
          mailchimp_group_list_id
          mailchimp_tag_id
        }
      }
    }
  }
`
